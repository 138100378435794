<template>
  <base-material-card id="box-list">
    <BoxOptions @reload="reload" @load="loaded" />

    <template
      v-if="
        ($store.state.boxes.boxes.length === 0 || noBox) &&
          $store.getters['auth/isTattooer'] &&
          load
      "
    >
      <v-row justify="center">
        <v-card>
          <v-card-title>
            <v-row class="ma-2">
              <v-col>
                <v-row justify="center">
                  <v-col style="text-align: center">
                    {{ $t("no_boxes") }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-row>
    </template>
    <template v-else-if="load">
      <template v-if="tab">
        <CalendarDay v-if="btn === 'day'" ref="CalendarDay" />
        <template v-else>
          <CalendarWeekPercentage
            v-if="!tab_week"
            ref="CalendarWeekPercentage"
          />
          <CalendarWeekBox
            v-else-if="filtersWeekState.box"
            ref="CalendarWeekBox"
          />
          <CalendarWeekTattooer
            v-else-if="filtersWeekState.tattooer"
            ref="CalendarWeekTattooer"
          />
          <CalendarWeek v-else ref="CalendarWeek" />
        </template>
      </template>
      <template v-else>
        <List v-if="$store.getters['auth/isStudio']" ref="List" />
        <ReservationTattooer v-else ref="ReservationTattooer" />
      </template>
    </template>

    <ReservationCard @reload="reload" />
  </base-material-card>
</template>

<script>
import * as moment from "moment";
import { mapActions, mapState } from "vuex";
export default {
  name: "BoxList",
  data() {
    return {
      load: false,
    };
  },
  components: {
    BoxOptions: () => import("@/components/boxes/Options"),
    List: () => import("@/components/boxes/Views/List"),
    ReservationTattooer: () =>
      import("@/components/boxes/Views/ReservationTattooer"),
    CalendarDay: () => import("@/components/boxes/Views/CalendarDay"),
    CalendarWeek: () => import("@/components/boxes/Views/CalendarWeek"),
    CalendarWeekBox: () => import("@/components/boxes/Views/CalendarWeekBox"),
    CalendarWeekPercentage: () =>
      import("@/components/boxes/Views/CalendarWeekPercentage"),
    CalendarWeekTattooer: () =>
      import("@/components/boxes/Views/CalendarWeekTattooer"),
    ReservationCard: () =>
      import("@/components/boxes/components/ReservationCard"),
  },
  computed: {
    ...mapState("boxes", [
      "tabs",
      "filtersState",
      "filtersWeekState",
      "boxState",
      "dateState",
    ]),
    noBox() {
      let s = this.$store.state.boxes.studios.find(
        (s) => s.id === this.$store.state.boxes.studioId
      );
      if (s) {
        if (s.user.setting.length <= 0 || s.user.setting[0].value == 0) {
          return true;
        }
      }
      return false;
    },
    /**
     * Options
     */
    btn: {
      get() {
        return this.tabs.btn;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "btn" });
      },
    },
    tab: {
      get() {
        return this.tabs.tab;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "tab" });
      },
    },
    tab_day: {
      get() {
        return this.tabs.tab_day;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "tab_day" });
      },
    },
    tab_week: {
      get() {
        return this.tabs.tab_week;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "tab_week" });
      },
    },
  },
  mounted() {
    this.$store.commit("boxes/SET_DATE", moment().format("YYYY-MM-DD"));
  },
  methods: {
    ...mapActions("timetables", ["getTimetables", "getTimetableResume"]),
    fetchTimeTables() {
      console.log("FETCH TIMETABLES");
      this.getTimetableResume({
        studio_id: this.$store.getters["auth/isStudio"]
          ? this.$store.state.auth.user.studio.id
          : this.$store.state.boxes.studioId,
      });
      this.getTimetables({
        filters: {
          studio_id: this.$store.getters["auth/isStudio"]
            ? this.$store.state.auth.user.studio.id
            : this.$store.state.boxes.studioId,
        },
      });
    },
    loaded() {
      this.load = true;
      this.fetchTimeTables();
    },
    reload() {
      if (this.$refs.CalendarDay) this.$refs.CalendarDay.fetchTable();

      if (this.$refs.CalendarWeekPercentage)
        this.$refs.CalendarWeekPercentage.fetchWeek();

      if (this.$refs.CalendarWeekBox) this.$refs.CalendarWeekBox.filterTable();

      if (this.$refs.CalendarWeekTattooer)
        this.$refs.CalendarWeekTattooer.filterTable();

      if (this.$refs.CalendarWeek) this.$refs.CalendarWeek.fetchWeek();

      if (this.$refs.List) {
        this.$refs.List.fetchBoxes();
        this.$refs.List.fetchStatusList();
      }

      if (this.$refs.ReservationTattooer)
        this.$refs.ReservationTattooer.fetch();
    },
  },
};
</script>

<style scoped></style>
